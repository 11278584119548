import React from 'react';
import Helmet from 'react-helmet';

import Copy, { ListItem, OrderedList, UnorderedList } from '@nib-components/copy';

import Layout from '../../components/Layout';
import { ContentfulDocument } from '../../constructs/models';
import metrics from '../../metrics';

import LifeLivingInsuranceSection, {
  LifeLivingInsuranceSectionProps,
} from '../../page-sections/life-living-insurance-section';
import { ContentHeroPanelProps } from '../../components/ContentHeroPanel';
import WomanWithSpoonImage from '../../img/life-and-living/woman-with-spoon.jpeg';
import CurrentOfferImage from '../../img/life-and-living/life-living-insurance-discount.svg';
import { NavContentProps, NavLinkProps } from '../../components/NavContent';
import { ImportantInformationBannerProps } from '../../components/ImportantInformationBanner';
import Link from '@nib-components/link';
import { ExternalLinkSystemIcon } from '@nib/icons';
import { Box, Section, Stack } from '@nib/layout';
import Heading from '@nib-components/heading';
import { PrimaryButton } from '@nib-components/button';
import { urlConstants } from '../../constructs/constants';
import { colorBlack } from '@nib-components/theme';

const title = 'Life Insurance & Living Insurance - Special Offers';
const description =
  'New Zealanders can get peace of mind if they pass away or can’t work anymore with life insurance or illness and income protection cover from nib.';

const contentHeroData: ContentHeroPanelProps = {
  title: (
    <>
      Let’s talk about <span style={{ color: '#82E578' }}>Life & Living insurance.</span>
    </>
  ),
  subtextLeft: 'LIFE INSURANCE',
  subtextRight: 'LIVING INSURANCE',
  description: <>It’s healthy to talk about death. It’s even better to plan for it.</>,
  imageMobile: WomanWithSpoonImage,
  imageDesktop: WomanWithSpoonImage,
  imageAlt:
    'Women with spoon in mouth, eyes closed,  looking happy and satisfied against a blue wall.',
  type: 'banner',
};

const navigationLinks: NavLinkProps[] = [
  {
    text: 'About Life & Living insurance',
    isActive: false,
    href: urlConstants.aboutLifeLivingInsurance,
  },
  {
    text: 'Special offers',
    isActive: true,
    href: urlConstants.currentOfferLifeLivingInsurance,
  },
  {
    text: 'Why get Life & Living insurance',
    isActive: false,
    href: urlConstants.whyLifeLivingInsuranceCoverIsImportant,
  },
  {
    text: 'Estimator Tool',
    isActive: false,
    href: urlConstants.estimateYourCoverageLifeLivingInsurance,
  },
  {
    text: 'For homeowners',
    isActive: false,
    href: urlConstants.lifeLivingHomeowners,
  },
  { text: 'How to apply', isActive: false, href: urlConstants.howToApplyLifeLivingInsurance },
];

const contentData = {
  title: 'A game plan for life.',
  description: (
    <Copy>
      Our Life and Living Insurance has a range of options to protect what matters to you. Now more
      affordable with $300 off after paying for your first month*.
      <br />
      <br />
      Plus get complimentary Funeral Cover with any nib Life & Living Insurance option.
      <br />
      <br />
      *$300 credit is applied per policy in the second month after your policy is issued. Offer
      applies to all new applications received by nib between 1 March – 30 June '25.{' '}
      <Link href="#offer-terms">T&Cs apply.</Link>
    </Copy>
  ),
  buttonText: 'Find out how to apply',
  buttonHref: urlConstants.howToApplyLifeLivingInsurance,
  image: CurrentOfferImage,
  imageAlt: 'Promotional image offering $300 discount on life and living insurance plans.',
};

const navigation: NavContentProps = {
  title: 'Life & Living insurance',
  homeHref: urlConstants.compareLifeAndLivingInsurance,
  primaryButton: { text: 'Apply now', href: urlConstants.estimateYourCoverageLifeLivingInsurance },
  secondaryButton: { text: 'Contact us', href: urlConstants.lifeLivingInsuranceContactUs },
  navigation: navigationLinks,
};

const importantInformationData: ImportantInformationBannerProps = {
  title: 'Important Information',
  description: [
    <Stack space={4}>
      <Copy measure={false} size="small">
        Only the main features and benefits of the Life & Living Insurance is outlined here. For a
        full explanation of all benefits, exclusions and other terms and conditions of the cover,
        view the{' '}
        <Link href={urlConstants.lifeAndLivingInsuranceWording} icon={ExternalLinkSystemIcon}>
          Life & Living Insurance wording
        </Link>
        .
      </Copy>
      ,
      <Copy measure={false} size="small">
        Life & Living Insurance is provided by nib nz insurance limited and is a related company of
        nib nz limited (“nib”). nib nz insurance limited issues and underwrites Life & Living
        Insurance products, and is solely responsible for claims under the cover. nib sells and may
        advise you on these products. Find out more about{' '}
        <Link href={urlConstants.financialStrength}>
          financial strength and financial advice with nib
        </Link>
        .
      </Copy>
      <Heading component="h3" color="trueGreen" id="offer-terms">
        *Get $300 Off Any Life & Living Insurance Policy - Terms and Conditions
      </Heading>
      <OrderedList>
        <ListItem measure={false}>
          <Copy measure={false} size="small">
            You (the policy owner) are eligible for this offer (Offer) if you submit an application
            for a new Life & Living Insurance product (Eligible Policy) between 1 March and 30 June
            2025 (or such later date as nib may permit in its absolute discretion) and your
            application for cover is accepted by nib.
          </Copy>
          <Copy measure={false} size="small">
            The Offer of $300 off will be applied as a credit on the Eligible Policy in the second
            month after your Eligible Policy is issued and after you’ve made the minimum premium
            payments:
          </Copy>
          <UnorderedList>
            <ListItem measure={false} size="small">
              If you pay weekly, the first four weekly premium payments need to be paid
            </ListItem>
            <ListItem measure={false} size="small">
              If you pay fortnightly, the first two fortnightly premium payments need to be paid
            </ListItem>
            <ListItem measure={false} size="small">
              If you pay by another frequency, the first premium payment needs to be paid
            </ListItem>
          </UnorderedList>
        </ListItem>
        <ListItem measure={false} size="small">
          The credit is not transferable, refundable or redeemable for cash.
        </ListItem>
        <ListItem measure={false} size="small">
          Maximum credit value of $300 per Eligible Policy, limited to one Eligible Policy for any
          one person insured during this promotional period, between 1 March and 30 June 2025.
        </ListItem>
        <ListItem measure={false} size="small">
          The credit will automatically go towards future premiums on the Eligible Policy and when
          the credit runs out, premiums will be payable at the applicable rate and the Eligible
          Policy will continue to be charged on the selected payment frequency. The credit may
          result in a partial payment being due, before payments return to the regular premium
          amount.
        </ListItem>
        <ListItem measure={false} size="small">
          The Offer cannot be used in conjunction with any other Life & Living Insurance offers.
        </ListItem>
        <ListItem measure={false} size="small">
          These terms and conditions may be updated or the Offer cancelled by nib provided this does
          not prejudice anyone who has already received the Offer. They were last updated on 20
          February 2025. nib will publish any change on our website{' '}
          <Link href="/">https://nib.co.nz</Link>.
        </ListItem>
        <ListItem measure={false} size="small">
          The Offer is promoted by nib nz insurance limited, 0800 555 642,{' '}
          <Link href="mailto:lifesales@nib.co.nz">lifesales@nib.co.nz</Link>. Level 9, 48 Shortland
          Street, Auckland City, 1010
        </ListItem>
      </OrderedList>
    </Stack>,
  ],
};

const lifeLivingSectionData: LifeLivingInsuranceSectionProps = {
  heroContent: contentHeroData,
  navigation: navigation,
  importantInformationData: importantInformationData,
};

type DocumentResult = {
  edges: {
    node: {
      document: ContentfulDocument;
    };
  }[];
};

interface ImageShape {
  file: {
    url: string;
  };
}

interface HtmlMarkdownShape {
  childMarkdownRemark: {
    html: string;
  };
}
interface RawAndHtmlMarkdownShape {
  childMarkdownRemark: {
    html: string;
    rawMarkdownBody: string;
  };
}

interface HeroImagesShape {
  desktop: ImageShape;
  desktop2x4000x1200: ImageShape;
  mobile2x800x300: ImageShape;
  mobile400x150: ImageShape;
  tablet2x1600x580: ImageShape;
  tablet800x290: ImageShape;
}

interface NodeShape {
  title: string;
  startDate: string;
  heroTitle: string;
  heroCopy: RawAndHtmlMarkdownShape;
  leftButtonText: string;
  rightButtonText: string;
  legal: RawAndHtmlMarkdownShape;
  campaignCode: string;
  heroImage: HeroImagesShape;
  importantThingsToKnow: HtmlMarkdownShape;
}

type LifeCoverOptionsPageResult = {
  edges: {
    node: NodeShape;
  }[];
};

type GraphQLResult = {
  temporaryAccidentalDeathCover: DocumentResult;
  lifePolicyDocument: DocumentResult;
  factSheetUrl: DocumentResult;
  lifeCoverOptionsPage: LifeCoverOptionsPageResult;
};

interface LifeCoverOptionsProps {
  data: GraphQLResult;
}

const LifeAndLivingOffer = ({ data }: LifeCoverOptionsProps): JSX.Element => {
  const schema = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    '@id': 'WebPage',
    identifier: 'https://www.nib.co.nz/life-cover-options/special-offers',
    url: 'https://www.nib.co.nz/life-cover-options/special-offers',
    description:
      'New Zealanders can get peace of mind if they pass away or can’t work anymore with life insurance or illness and income protection cover from nib.',
    name: ['Life Insurance & Living Insurance - Special Offers | nib'],
    isPartOf: 'https://www.nib.co.nz',
  };
  const JSONschema = JSON.stringify(schema);

  return (
    <Layout>
      <Helmet>
        <title>{title} | nib</title>
        <meta name="description" content={description} />
        <script type="application/ld+json">{JSONschema}</script>
      </Helmet>
      <LifeLivingInsuranceSection {...lifeLivingSectionData}>
        <MainContent />
      </LifeLivingInsuranceSection>
    </Layout>
  );
};

const MainContent = () => {
  return (
    <Section>
      <Box
        display="flex"
        justifyContent="start"
        flexDirection={{ xs: 'column', xxxl: 'row-reverse' }}
        gap="4"
        padding="3"
      >
        <Box display="flex" justifyContent={{ xs: 'center', xl: 'flex-start' }}>
          <img src={contentData.image} alt={contentData.imageAlt} />
        </Box>
        <Stack space={4}>
          <Heading color="trueGreen" size={2} component="h2">
            {contentData.title}
          </Heading>
          <Copy measure={false} color={colorBlack}>
            {contentData.description}
          </Copy>
          <Box>
            <PrimaryButton href={contentData.buttonHref}>{contentData.buttonText}</PrimaryButton>
          </Box>
        </Stack>
      </Box>
    </Section>
  );
};

export default metrics({ pageName: 'life-and-living-estimate-your-coverage' })(LifeAndLivingOffer);
